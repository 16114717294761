.swiper-container {
  height: 100%;
  position: relative;
}
.lg-banner {
  height: 520px;
  background: #eee;
  font-family: sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
  position: relative;
}
.lg-banner /deep/ .swiper-paginationlg {
  position: relative;
  bottom: 30px;
  width: 100%;
  text-align: center;
  z-index: 10;
}
.lg-banner /deep/ .swiper-paginationlg .swiper-pagination-bullet {
  width: 38px;
  height: 5px;
  display: inline-block;
  border-radius: 5px;
  margin: 3px 5px;
  background: #f8f6f6e6;
  opacity: 0.2;
}
.lg-banner /deep/ .swiper-paginationlg .swiper-pagination-bullet-active {
  background: #43a0ece6;
  opacity: 1;
}
.lg-banner .swiper-wrapper {
  transition-delay: 1s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
}
.lg-banner .swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.lg-banner .title {
  transition-delay: 1s;
  z-index: 10;
}
.lg-banner .title h3 {
  font-weight: 500;
  font-size: calc(27.73658537px);
  letter-spacing: 5px;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 2px #fff;
}
.lg-banner .img-box {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.9;
  overflow: hidden;
}
.lg-banner .img-box img {
  width: 100%;
  height: 100%;
}
.lg-banner .button-prev,
.lg-banner .button-next {
  transition: 0.5s;
  outline: none;
  position: absolute;
  width: 140px;
  z-index: 10;
  top: 50%;
  transform: translateY(-34px);
  cursor: pointer;
}
.lg-banner .button-prev {
  left: 5vw;
}
.lg-banner .button-next {
  right: 5vw;
}
.lg-banner .button.disabled {
  opacity: 0.2;
  cursor: default;
}
.lg-banner #arrow-svg-home {
  transform: translateY(353px);
}
.lg-banner .button-next #arrow-svg-home {
  transform: translateY(353px) rotateY(180deg);
  transform-origin: 80px 0px 0px;
}
.lg-banner svg {
  transition: 0.5s;
}
.lg-banner .cls-1 {
  transition: 0.5s;
  opacity: 0.4;
  transform-origin: -20px 40px;
  opacity: 1;
}
.lg-banner .cls-4 {
  transition: 0.5s;
  stroke-width: 2px;
  stroke: #fff;
  fill: none;
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  opacity: 0.4;
  transform-origin: 0px 0px 0px;
}
.lg-banner #arrow-trg {
  transition: 0.5s;
  fill: #fff;
  transform: rotateY(180deg) translate(-53px, 39px);
}
.lg-banner #line {
  transition: 0.5s;
  stroke: #fff;
  transform: translate(50px, 42px);
}
.lg-banner .button-prev:not(.disabled):hover svg {
  transform: translateX(-25px);
}
.lg-banner .button-next:not(.disabled):hover svg {
  transform: translateX(25px);
}
.lg-banner .button:not(.disabled):hover .cls-1 {
  transform: scale(1.1);
}
.lg-banner .button:not(.disabled):hover .cls-4 {
  stroke-dasharray: 2px;
  stroke-dashoffset: 2px;
  opacity: 1;
}
.lg-banner .button:not(.disabled):hover #arrow-trg {
  transform: rotateY(180deg) translate(-37px, 39px);
}
.lg-banner .button:not(.disabled):hover #line {
  transform: translate(35px, 42px) scaleX(0.33);
}
.md-sm-banner {
  height: 100%;
  background: #eee;
  font-family: sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
  position: relative;
}
.md-sm-banner .swiper-container {
  width: 100%;
  height: 100%;
  background-color: #b3bdc4;
  transition: 1s background-color 1.3s;
}
.md-sm-banner .swiper-container .swiper-wrapper {
  transition-delay: 1s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
}
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide .title {
  transition-delay: 1s;
  z-index: 10;
}
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide .title h3 {
  font-weight: 700;
  font-size: calc(27.73658537px);
  letter-spacing: -1px;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 2px #fff;
}
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide .img-box {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: scale(0.6, 0.6);
  transition-duration: 1s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  opacity: 0.9;
  overflow: hidden;
}
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide .img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.2, 1.2) translateX(50%);
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  transition-property: transform;
}
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide .button-prev,
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide .button-next {
  transition: 0.5s;
  outline: none;
  position: absolute;
  width: 140px;
  z-index: 10;
  top: 45vh;
  transform: translateY(-34px);
  cursor: pointer;
}
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide .button-prev {
  left: 5vw;
}
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide .button-next {
  right: 10vw;
}
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide .button.disabled {
  opacity: 0.2;
  cursor: default;
}
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide #arrow-svg-home {
  transform: translateY(353px);
}
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide .button-next #arrow-svg-home {
  transform: translateY(353px) rotateY(180deg);
  transform-origin: 80px 0px 0px;
}
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide svg {
  transition: 0.5s;
}
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide .cls-1 {
  transition: 0.5s;
  opacity: 0.4;
  transform-origin: -20px 40px;
  opacity: 1;
}
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide .cls-4 {
  transition: 0.5s;
  stroke-width: 2px;
  stroke: #fff;
  fill: none;
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  opacity: 0.4;
  transform-origin: 0px 0px 0px;
}
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide #arrow-trg {
  transition: 0.5s;
  fill: #fff;
  transform: rotateY(180deg) translate(-53px, 39px);
}
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide #line {
  transition: 0.5s;
  stroke: #fff;
  transform: translate(50px, 42px);
}
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide .button-prev:not(.disabled):hover svg {
  transform: translateX(-25px);
}
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide .button-next:not(.disabled):hover svg {
  transform: translateX(25px);
}
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide .button:not(.disabled):hover .cls-1 {
  transform: scale(1.1);
}
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide .button:not(.disabled):hover .cls-4 {
  stroke-dasharray: 2px;
  stroke-dashoffset: 2px;
  opacity: 1;
}
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide .button:not(.disabled):hover #arrow-trg {
  transform: rotateY(180deg) translate(-37px, 39px);
}
.md-sm-banner .swiper-container .swiper-wrapper .swiper-slide .button:not(.disabled):hover #line {
  transform: translate(35px, 42px) scaleX(0.33);
}
.xs-banner {
  position: relative;
}
.xs-banner /deep/ .swiper-pagination {
  width: 100%;
  bottom: 3.5px;
}
.xs-banner /deep/ .swiper-pagination .swiper-pagination-bullet {
  width: 22px;
  height: 4px;
  border-radius: 8px;
  margin: 0 3.5px;
}
