.txtArea > div:nth-of-type(1) {
  font-size: 3rem;
  font-weight: 400;
  margin-top: 25px;
  color: #333;
}
.txtArea > div:nth-of-type(2) {
  margin-top: 1.5rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 22px;
  color: #666;
  height: 85px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.tryOutBtn {
  margin-top: 5%;
  width: 12rem;
  height: 3.3rem;
  line-height: 3.3rem;
  text-align: center;
  border-radius: 2.1rem;
  border: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}
img {
  width: 90%;
  border-radius: 3px;
}
