



































































































































































































































































































@media screen and (min-width: 200px) and (max-width: 768px) {
  .banner-three {
    margin: 40px 0 25px 0;
    height: 300px;
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .member-info {
      width: 190px;
      height: 260px;
      border-radius: 3px;
      overflow: hidden;
      padding-bottom: 10px;
      box-shadow: 10px 7px 12px 0px #8888885e;
      .img-box {
        width: 100%;
        height: 126px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }

      & > p:nth-of-type(1) {
        font-size: 18px;
        margin: 6px 5px;
        font-weight: 700;
        font-family: serif;
      }
      & > p:nth-of-type(2) {
        font-size: 16px;
        margin: 4px 5px;
      }
    }
  }
}

@import "../../styles/index";
.carousel-page {
  width: 100%;
}
.banner-one {
  height: auto;
  box-sizing: border-box;
  padding: 5px 0;
  .banner-one-nav {
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    li {
      display: flex;
      height: 50px;
      width: 25%;
      font-size: 20px;
      color: #8d8d8d;
      background: #fff;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        color: #d89a60b9;
      }
    }
    .active {
      color: #d89a60b9;
      font-weight: 600;
      border-bottom: 3px #d89a60b9 solid;
    }
  }
}
.banner-two {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  height: 250px;
  border-radius: 10px;
  overflow: hidden;
  width: 88vw;
  margin: auto;
  /deep/.swiper-container {
    position: relative;
    height: 100%;
    .parallax-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 130%;
      height: 100%;
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center;
      background-image: url("../../assets/huaihua.jpg");
    }
    .swiper-slide {
      position: relative;
      font-size: 18px;
      color: #fff;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 40px 60px;
      background-color: rgba(0, 0, 0, 0.4);
      .title {
        font-size: 28px;
        font-weight: 300;
      }
      .subtitle {
        font-size: 20px;
      }
      .text {
        font-size: 14px;
        max-width: 450px;
        height: 55px;
        line-height: 1.3;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}
@media screen and (min-width: 720px) {
  .banner-three {
    margin: 40px 0 25px 0;
    height: 300px;
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;

      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
    .member-info {
      width: 190px;
      height: 260px;
      border-radius: 3px;
      overflow: hidden;
      padding-bottom: 10px;
      box-shadow: 10px 7px 12px 0px #8888885e;
      .img-box {
        width: 100%;
        height: 190px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }

      & > p:nth-of-type(1) {
        font-size: 16px;
        margin: 6px 5px;
      }
      & > p:nth-of-type(2) {
        font-size: 13px;
        margin: 4px 5px;
      }
    }
  }
}
