

















































































































































































































































































































































@media screen and (min-width: 200px) and (max-width: 768px) {
  .css_carousel {
    .img_css {
      width: 100%;
      height: 77%;
    }
  }
  .home-aboutUs {
    & > div:nth-of-type(1) {
      font: 16px Arial, "微软雅黑";
      position: relative;
      z-index: 5;
      color: #929292;
      // height: 500px;
      // padding: 35px 50px;
      max-width: 1160px;
      margin: auto;
      box-sizing: border-box;
      background: #fff;
      .title {
        font-family: monospace;
        font-size: 3rem;
        font-weight: 600;
        color: #333;
        letter-spacing: 0.067rem;
        text-align: center;
        max-width: 300px;
        margin: 0 auto 10px auto;
        padding-bottom: 6px;
        margin-top: 40px;
      }
      .subTitle {
        font-size: 12px;
        line-height: 30px;
        color: #a5a5a5;
        text-align: center;
      }
      .content {
        line-height: 20px;
        text-indent: 25px;
        margin: 5px auto 4px auto;
        font-family: fantasy;
      }
      .more-btn {
        width: 150px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin: 60px auto 105px auto;
        border-radius: 2px;
        border: 1px solid #8d8d8d;
        cursor: pointer;
        &:hover {
          background: #fff;
          color: black;
        }
      }
    }
    & > div:nth-of-type(2) {
      z-index: 1;
      width: 100%;
      top: -90px;
      background-color: #e3c7af63;
      height: 220px;
      position: relative;
    }
  }

  .css_carousel .css_tite h3 {
    position: absolute;
    top: 0;
    z-index: 999;
    color: white;
    // opacity: 1;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 365px;
  }
  .css_shouapp {
    display: none !important;
  }
  .css_gd {
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    font-weight: 600;
    font-family: none;
  }
  .css_titeapp {
    width: 100vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 20px;
    font-size: 16px;
  }
  .more {
    img {
      display: none;
    }
  }
  .css_span {
    display: flex;
    justify-content: center;
    font-family: monospace;
    font-size: 3rem;
    font-weight: 600;
    color: #333;
    letter-spacing: 0.067rem;
    text-align: center;
    max-width: 300px;
    margin: 0 auto 10px auto;
    padding-bottom: 6px;
  }

  .css_app {
    .css_tite {
      width: 100vw;
      margin-top: -6px;
      height: 55px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    /deep/.el-carousel__indicator--horizontal {
      display: none;
    }
  }
}

.css_shou {
  cursor: pointer;
}
.css_carousel .css_tite h3 {
  position: absolute;
  top: 0;
  z-index: 999;
  color: white;
  // opacity: 1;
  font-weight: 500;
}
.css_tite {
  width: 50vw;
  height: 49px;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 251px;
  background: gray;
  opacity: 0.8;
}

// 底部文字
.purpose {
  line-height: 10rem;
  background: #bda09d;
  // opacity: 0.3;
  // color: #5E5E5E;
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-family: serif;
  font-weight: 600;
}

@media screen and (min-width: 720px) {
  .css_carousel {
    .img_css {
      width: 100%;
    }
  }
  .home-aboutUs {
    & > div:nth-of-type(1) {
      font: 16px Arial, "微软雅黑";
      position: relative;
      z-index: 5;
      color: #929292;
      // height: 500px;
      // padding: 35px 50px;
      max-width: 1160px;
      margin: auto;
      box-sizing: border-box;
      background: #fff;
      .title {
        font-family: monospace;
        font-size: 3rem;
        font-weight: 600;
        color: #333;
        letter-spacing: 0.067rem;
        text-align: center;
        max-width: 300px;
        margin: 0 auto 10px auto;
        padding-bottom: 6px;
      }
      .subTitle {
        font-size: 12px;
        line-height: 30px;
        color: #a5a5a5;
        text-align: center;
      }
      .content {
        line-height: 20px;
        text-indent: 25px;
        margin: 5px auto 4px auto;
        font-family: fantasy;
      }
      .more-btn {
        width: 150px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin: 60px auto 105px auto;
        border-radius: 2px;
        border: 1px solid #8d8d8d;
        cursor: pointer;
        &:hover {
          background: #fff;
          color: black;
        }
      }
    }
    & > div:nth-of-type(2) {
      z-index: 1;
      width: 100%;
      top: -90px;
      background-color: #e3c7af63;
      height: 220px;
      position: relative;
    }
  }
  .home-service {
    max-width: 1060px;
    margin: 20px auto;
    box-sizing: border-box;
    max-width: 1160px;
    .service-wrapper {
      width: 100%;
      margin: 30px 0;
      display: flex;
      overflow: hidden;
      justify-content: space-between;
      div {
        width: 18%;
        height: 200px;
        display: flex;
        flex-wrap: wrap;
        .imgBox {
          width: 100%;
          height: 140px;
          overflow: hidden;
          border-radius: 4px;
          img {
            width: auto;
            height: 100%;
            display: inline-block;
            margin: auto;
          }
        }
        p {
          width: 100%;
          padding: 15px 5%;
          text-align: center;
          font-family: serif;
          font-size: 18px;
          font-weight: 700;
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .home-service {
    max-width: 720px;
    margin: 20px auto;
    box-sizing: border-box;
    max-width: 1160px;
    .service-wrapper {
      width: 100%;
      margin: 30px 0;
      display: flex;
      overflow: hidden;
      justify-content: space-between;
      div {
        width: 18%;
        height: 200px;
        display: flex;
        flex-wrap: wrap;
        .imgBox {
          width: 100%;
          height: 70px;
          overflow: hidden;
          border-radius: 4px;
          img {
            width: auto;
            height: 100%;
            display: inline-block;
            margin: auto;
          }
        }
        p {
          width: 100%;
          padding: 15px 5%;
          text-align: center;
          font-family: serif;
          font-size: 15px;
          font-weight: 700;
          margin: 0;
        }
      }
    }
  }
}

.home-overview {
  .flex-overview {
    margin: 20px auto 20px auto;
    .overview-item {
      max-width: 1160px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin: 50px auto;
      color: #fff;
      .description {
        width: 50%;
        height: 280px;
        padding: 2% 5%;
        box-sizing: border-box;
        background-color: #292929;
        & > p:nth-of-type(1) {
          font-size: 18px;
          font-family: fangsong;
          font-weight: 600;
          margin-bottom: 10px;
        }
        & > p:nth-of-type(2) {
          font-size: 15px;
        }
      }
      .img {
        width: 50%;
        height: 280px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          display: inline-block;
          margin: auto;
        }
      }
      & > div:nth-of-type(1) {
        overflow: hidden;
        border-radius: 12px 0 0 12px;
      }
      & > div:nth-of-type(2) {
        overflow: hidden;
        border-radius: 0 12px 12px 0;
      }
    }
  }
}

.home-CCHuaiHua {
  max-width: 1180px;
}

.home-needToKnow {
  max-width: 1160px;
  border-radius: 3px;
  height: auto;
  width: 1160px;
  overflow: hidden;
  margin: 10px auto 0px auto;
  img {
    width: 100%;
  }
  &:hover {
    cursor: pointer;
  }
}
#home-needToKnow {
  margin: 10px auto 0px auto;
}
.el-carousel__item h3 {
  color: black;
  font-size: 18px;
  opacity: 0.75;
  // line-height: 300px;
  // margin-left: 50%;
  // margin-top: 43%;
}

.el-carousel__item:nth-child(2n) {
  // background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  // background-color: #d3dce6;
}
.css_carousel {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  height: 100%;
}
